<template>
  <div id="knowledgeTree" data-id="s0">
    <input type="text" readonly="readonly" :value="checkedTitle" autocomplete="off" @click.stop="handleTreeClick"  placeholder="请选择" class="el-input__inner">
    <span class="el-input__suffix" style="box-sizing: border-box">
      <span class="el-input__suffix-inner">
        <i :class='arrowClass'></i>
      </span>
    </span>
    <el-tree
        :class="treeClass"
        :data="items"
         node-key="id"
        @node-click="handleNodeClick"
        :props="defaultProps" >
    </el-tree>
  </div>
</template>

<script>
export default {
  props:['checkedItem'],
  data(){
    return{
      name: "Tree",
      props: {
        label: 'name',
        children: 'zones',
        isLeaf: 'leaf'
      },
      treeShow:false,
      arrowClass:'el-select__caret el-input__icon el-icon-arrow-up transform-180',
      treeClass:'select-tree hide',
      items:[],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      checkedTitle:'',
      selId:'',
    }
  },
  methods: {
    handleTreeClick(){
      this.treeShow = !this.treeShow;
      if(this.treeShow){
        this.arrowClass = 'el-select__caret el-input__icon el-icon-arrow-up'
        this.treeClass  = 'select-tree show'
      }
      else{
        this.arrowClass = 'el-select__caret el-input__icon el-icon-arrow-up  transform-180'
        this.treeClass  = 'select-tree hide'
      }
    },
    hideTree(){
      if(this.treeShow){
        this.treeShow=false;
        this.arrowClass = 'el-select__caret el-input__icon el-icon-arrow-up  transform-180'
        this.treeClass  = 'select-tree hide'
      }
    },
    handleNodeClick(data) {
      this.checkedTitle = data.title;
      this.selId = data.id;
      this.$emit('parentEvent',this.selId)
      this.hideTree();
    },
    initData (url,checkedVal){
      if(checkedVal == null){
        this.checkedTitle='';
      }
      this.$http({
        method: "get",
        url: url,
      }).then((res) => {
        this.items = res.data.list;
        if(checkedVal != null){
          for(let i=0;i<this.items.length;i++){
            let item = this.items[i];
            if(item.id == checkedVal){
              this.checkedTitle = item.title;
            }
            else{
              for(let j=0;j<item.children.length;j++){
                let child = item.children[j];
                if(child.id == checkedVal){
                  this.checkedTitle = child.title;
                }
              }
            }
          }
        }
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getCheckedIds(){
      const ids = [];
      for(let i=0; i<this.items.length; i++){
        ids.push(this.items[i].id);
      }
      return ids;
    }
  },
  mounted() {}
}
</script>

<style scoped>
input{
  cursor: pointer;
}
div{
  margin: 0px;
  padding: 0px;
}
.select-tree{
  position: absolute;
  border: 1px solid #DCDFE6;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1000;
  margin-top:5px;
  width: 100%;
  height: 300px;
  overflow: auto;
}
.transform-180{
  transform:rotate(180deg);
}

/*为下拉框展开时添加名称为slide-down的关键帧动画*/
@-webkit-keyframes slide-down{
  0%{transform: scale(1,0);}
  100%{transform: scale(1,1);}
}
.show {
  transition: max-height .1s ease-in;
  transform-origin: 50% 0;
  animation: slide-down .1s ease-in;
  -webkit-animation: slide-down .1s ease-in;
}

.hide {
  display: none;
  transition: max-height .1s ease-out;
}
</style>
