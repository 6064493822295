<template>
  <el-form-item label="来源" :prop="this.prop">
    <select-tree-single ref="selectTree"  @parentEvent="getSelId" data-id="0"></select-tree-single>
  </el-form-item>
</template>

<script>
import {UrlEnum} from "../../public/js/common-vue";
import SelectTreeSingle from "@/components/SelectTreeSingle";

export default {
  name: "SourceSelectTree",
  props:['parent','prop'],
  components: {SelectTreeSingle},
  data(){
    return{
      sourceUrl:UrlEnum.SOURCES_TREE,
    }
  },
  methods:{
    getSelId(data){
      this.$emit('parentEvent',data);
      if(this.prop != null){
        this.parent.$refs.form.validateField('sourceId',async (valid) => {if (valid) return false})
      }
    },
    hideTree(){
      this.$refs.selectTree.hideTree();
    },
    initData(checkedVal){
      this.$refs.selectTree.initData(this.sourceUrl,checkedVal);
    }
  }
}
</script>

<style scoped>

</style>
