<template>
  <el-select v-model="typeId" @change="handleChange" placeholder="请选择" style="width:100%">
    <el-option
        v-for="type in types"
        :key="type.id"
        :label="type.name"
        :value="type.id">
    </el-option>
  </el-select>
</template>

<script>
import {getLocalProjectId, UrlEnum} from "../../public/js/common-vue";

export default {
  name: "SelectType",
  data(){
    return{
      typeId:'',
      types:[]
    }
  },
  methods:{
    initData(checkedVal){
      this.$http({
        method: "get",
        url: UrlEnum.QUESTION_TYPE+"?projectId="+getLocalProjectId()+"&ids=4,5,6",
      }).then((res) => {
        this.types = res.data.list;
        if(checkedVal != null){
          this.typeId = checkedVal;
        }
        this.handleChange();
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    handleChange(){
      this.$emit('parentEvent',this.typeId)
    }
  },
  mounted() {}
}
</script>

<style scoped>

</style>
